import React from "react";
import _ from "lodash";
import TechPage from '../components/TechPage'
import Icon from '../components/Icon';
import data from '../media/icons/new/3-Data.svg';
import IllustrationHealthBill from '../media/illustration/HealthBill.png';

export default class TabPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 'real-world-data',
      title: "Real world data en santé",
      description: "La qualité et l'exploitation des données de vie réelle posent souvent des problèmes pour la réalisation d'étude observationnelle. Découvrer notre offre de service personnalisée pour bénéficier de la richesse offerte par les real world data.",
      content:
        <div class="columns section-panel">
            <div class="column alt-column is-8">
                <div class="content is-medium">
                <p>Les données de vie réelle (ou <i>real world data</i>) sont les données issues de formulaire de remboursement ou des dossiers de patients électroniques. Ce sont des sources d’information primordiales pour la compréhension des enjeux médicaux dans la « vraie vie » qui <strong>dépassent le cadre des essais cliniques</strong>.</p>
                <p>A titre d’exemple, 54% des patients atteints de certains lymphomes ne rentrent pas dans le cadre des lignes directrices éditées par les sociétés savantes car chaque patient est unique. Les données de vie réelle offrent une <strong>opportunité unique</strong> pour offrir une <strong>médecine plus personnalisée</strong> ou réaliser des <strong>recherches observationnelles</strong>.</p>
                <p>Malgré ces opportunités, de nombreux défis persistent&nbsp;:</p>
                <ul>
                    <li>au niveau de la <strong>qualité</strong> des données parfois incomplètes, non structurées ou incohérentes&nbsp;;</li>
                    <li>au niveau des <strong>contraintes réglementaires</strong>, notamment pour l’accès aux données&nbsp;;</li>
                    <li>au niveau de la <strong>répétabilité des études</strong> réalisées, mise à mal du fait du manque de standardisation de la donnée.</li>
                </ul>
                <p>Akimed aide les acteurs de la santé à surmonter ces défis en <strong>formant</strong> les data scientists, en mettant en place des <strong>plateformes de données</strong> robustes et en <strong>standardisant</strong> la donnée. Nous avons développé de nombreux outils pour transformer la donnée patient vers des formats standards comme OMOP ou FHIR.</p>
                </div>
            </div>
            <div class="column alt-column is-4">
                <div class="content is-medium">
                <figure class="image">
                    <Icon src={IllustrationHealthBill} />
                </figure>
                </div>
            </div>
        </div>
    };
  }

  render() {
    return (
      <TechPage 
        content={this.state.content} 
        activePage={this.state.page} 
        title={this.state.title}
        description={this.state.description}
      />
    );
  }
}
